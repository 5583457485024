import React from "react"
import { FormattedMessage, injectIntl } from "gatsby-plugin-intl"

import Container from "react-bootstrap/Container"

import FadeIn from "react-fade-in"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = ({ intl }) => (
  <Layout page="404">
    <SEO
      lang={intl.locale}
      title={`404: ${intl.formatMessage({ id: "title" })}`}
    />
    <Container fluid>
      <FadeIn>
        <h1>
          <FormattedMessage id="notfound.header" />
        </h1>
        <p>
          <FormattedMessage id="notfound.description" />
        </p>
      </FadeIn>
    </Container>
  </Layout>
)

export default injectIntl(NotFoundPage)
